<template>
  <div id="app">
    <TopMenu class="topMenu" />
    <router-view class="mainContent" />
  </div>
</template>
<script>
import TopMenu from '@/components/TopMenu.vue';
export default {
  components: {
    TopMenu
  },
  // beforeDestroy() {
  //   this.$store.commit('leave');
  // },
}
</script>
<style>
#app {
  font-family: Montserrat, Lato, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: linear-gradient(to right, #30d69a, #26bbbf, #0098c4, #30d69a);
  background-size: 600% 100%;
  animation: HeroBG 15s ease infinite;
  /* overflow: hidden; */
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 11% 83% 6%;
  min-height: 100vh;
  width: 100vw;
}

@keyframes HeroBG {
  0% { background-position:0 0 }
  50% { background-position:100% 0 }
  100% { background-position:0 0 }
}

.topMenu {
  grid-column: 2/3;
  grid-row: 1/2;
}

.mainContent {
  grid-column: 2/3;
  grid-row: 2/3;
  height: 100%;
  width: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
body {
  margin: 0;
  padding: 0;
}
@media only screen and (max-height: 450px)  and (orientation: landscape) {
  #app {
    height: 100vh;
    width: 100vw;
  }
}
@media only screen and (max-device-width: 46.9em)  and (orientation: portrait) {
  #app {
    height: 100vh;
    width: 100vw;
  }
}
</style>
