<template>
  <div class="topMenu">
    <div class="link1Wrapper">
      <p class="link1" @click="homeBtn">Home</p>
      <div class="divider1 hidden" id="divider1">
      </div>
    </div>
    <div class="link2Wrapper">
      <p class="link1" @click="aboutBtn">About</p>
      <div class="divider1 hidden" id="divider2">
      </div>
    </div>
    <div class="link3Wrapper">
      <p class="link1" @click="workBtn">Works</p>
      <div class="divider1 hidden" id="divider3">
      </div>
    </div>
    <div class="link4Wrapper">
      <p class="link1" @click="contactBtn">Contact</p>
      <div class="divider1 hidden" id="divider4">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopMenu',
  methods: {
    homeBtn() {
      this.$router.push('/')
    },
    aboutBtn() {
      this.$router.push('/about')
    },
    workBtn() {
      this.$router.push('/work')
    },
    contactBtn() {
      this.$router.push('/contact')
    }
  },
  computed: {
    getCurrentRouteName() {
      return this.$store.getters.getCurrentRouteName;
    }
  },
  watch: {
    getCurrentRouteName() {
      if (this.getCurrentRouteName === "Home") {
        document.getElementById('divider1').classList.remove('hidden')
        document.getElementById('divider2').classList.add('hidden')
        document.getElementById('divider3').classList.add('hidden')
        document.getElementById('divider4').classList.add('hidden')
      }
      else if (this.getCurrentRouteName === "About") {
        document.getElementById('divider1').classList.add('hidden')
        document.getElementById('divider2').classList.remove('hidden')
        document.getElementById('divider3').classList.add('hidden')
        document.getElementById('divider4').classList.add('hidden')
      }
      else if (this.getCurrentRouteName === "Work") {
        document.getElementById('divider1').classList.add('hidden')
        document.getElementById('divider2').classList.add('hidden')
        document.getElementById('divider3').classList.remove('hidden')
        document.getElementById('divider4').classList.add('hidden')
      }
      else if (this.getCurrentRouteName === "Contact") {
        document.getElementById('divider1').classList.add('hidden')
        document.getElementById('divider2').classList.add('hidden')
        document.getElementById('divider3').classList.add('hidden')
        document.getElementById('divider4').classList.remove('hidden')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.topMenu {
  display: grid;
  grid-template-columns: 68% 8% 8% 8% 8%;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
}
.topMenu p {
  font-size: 15pt;
  color: white;
  align-self: end;
  justify-self: center;
  margin: 0;
  padding: 0;
}
.divider1 {
  grid-row: 2/3;
  grid-column: 1/2;
  background-color: white;
  height: 4px;
  width: 60%;
  align-self: start;
  justify-self: center;
  margin-top: 0.5rem;
  opacity: 0.6;
  z-index: 0;
}
.link1Wrapper {
  grid-row: 1/2;
  grid-column: 2/3;
  cursor: pointer;
  display: grid;
  grid-template-rows: 65% 35%;
}
.link1 {
  grid-row: 1/2;
  grid-column: 1/2;
  align-self: end;
}
/* .divider1 {
  grid-row: 2/3;
  grid-column: 1/2;
  align-self: start;
  margin-top: 0.5rem;
} */
.link2Wrapper {
  grid-row: 1/2;
  grid-column: 3/4;
  cursor: pointer;
  display: grid;
  grid-template-rows: 65% 35%;
}
.link2 {

  cursor: pointer;
}
.link3Wrapper {
  grid-row: 1/2;
  grid-column: 4/5;
  cursor: pointer;
  display: grid;
  grid-template-rows: 65% 35%;
}
.link3 {

  cursor: pointer;
}
.link4Wrapper {
  grid-row: 1/2;
  grid-column: 5/6;
  cursor: pointer;
  display: grid;
  grid-template-rows: 65% 35%;
}
.link4 {
  cursor: pointer;
}
.hidden {
  opacity: 0;
}
@media only screen and (max-height: 450px)  and (orientation: landscape) {
  .topMenu p {
    font-size: 8pt;
  }
}
@media screen and (max-width: 1024px)  and (orientation: portrait) {
  .topMenu {
    display: grid;
    grid-template-columns: 1% 24% 25% 25% 25%;
    grid-template-rows: 100%;
  }
}
@media screen and (max-width: 700px)  and (orientation: portrait) {
  .topMenu p {
    font-size: 10pt;
  }
}
</style>
