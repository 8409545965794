<template>
  <div class="page1">
    <p class="mainTitle">My Skill Set</p>
    <div class="panelWrapper">
      <div class="coreSkill aboutPanels hidden" id="coreSkill" @click="skillBtn">
        <div class="panelTitleWrapper">
          <p class="panelTitle">Core Skills</p>
          <div class="divider">
          </div>
        </div>
        <img class="htmlIcon icon" src="@/assets/html_icon.png">
        <img class="cssIcon icon" src="@/assets/css_icon.png">
        <img class="javascriptIcon icon" src="@/assets/javascript_icon.png">
        <img class="vueIcon icon" src="@/assets/vue_icon.png">
      </div>
      <div class="subPanelWrapper">
        <div class="secondSkill aboutPanels hidden" id="secondSkill" @click="skillBtn">
          <div class="panelTitleWrapper">
            <p class="panelTitle">Secondary Skills</p>
            <div class="divider">
            </div>
          </div>
          <img class="nodeIcon" src="@/assets/node_icon.svg">
          <img class="electronIcon" src="@/assets/electron_icon.png">
          <img class="gitIcon" src="@/assets/git_logo2.svg">
        </div>
        <div class="hobbies aboutPanels hidden" id="hobbies" @click="hobbyBtn">
          <div class="panelTitleWrapper">
            <p class="panelTitle">Hobbies</p>
            <div class="divider">
            </div>
          </div>
          <img class="cameraIcon" src="@/assets/camera_icon.svg">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'About',
  methods: {
    skillBtn() {
      document.getElementById('coreSkill').classList.add('hidden');
      document.getElementById('secondSkill').classList.add('hidden');
      document.getElementById('hobbies').classList.add('hidden');
      setTimeout(() => {
        this.$router.push('/work')
      }, 300);
    },
    hobbyBtn() {
      // window.location.href = "http://www.simonxliu.com";
      window.open('http://www.simonxliu.com', '_blank');
    }
  },
  mounted() {
    setTimeout(() => {
      document.getElementById('coreSkill').classList.remove('hidden');
    }, 50);
    setTimeout(() => {
      document.getElementById('secondSkill').classList.remove('hidden');
    }, 200);
    setTimeout(() => {
      document.getElementById('hobbies').classList.remove('hidden');
    }, 400);
    let routeName = this.$route.name;
    this.$store.state.currentRouteName = routeName;
    console.log("Current Route Name: " + routeName);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page1 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 12% 88%;
  grid-template-columns: 100%;
}
.aboutPanels {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 4rem;
  /* display: grid;
  grid-template-columns: 10% 55% 30% 5%;
  grid-template-rows: 100%; */
}
.mainTitle {
  grid-column: 1/2;
  grid-row: 1/2;
  text-align: left;
  color: white;
  font-size: 3.5rem;
  font-weight: bold;
  margin: 0 0 0 5%;
}
.panelWrapper {
  display: grid;
  grid-template-columns: 49% 3% 48%;
  grid-template-rows: 100%;
  width: 100%;
}
.coreSkill {
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 12% 35% 6% 35% 12%;
  grid-template-rows: 12% 5% 37% 4% 37% 5%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.coreSkill:hover {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
}
.panelTitleWrapper {
  grid-column: 1/6;
  grid-row: 1/2;
  display: grid;
}
.panelTitle {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  color: white;
  justify-self: center;
  align-self: center;
  font-size: 2rem;
  padding: 0;
  margin: 0;
  z-index: 5;
}
.divider {
  grid-column: 1/2;
  grid-row: 1/2;
  background-color: white;
  height: 2px;
  width: 100%;
  align-self: end;
  opacity: 0.45;
  z-index: 0;
}
.icon {
  /* width: 100%; */
  height: 9.2em;
  justify-self: center;
  align-self: center;
}
.htmlIcon {
  grid-column: 2/3;
  grid-row: 3/4;
}
.cssIcon {
  grid-column: 4/5;
  grid-row: 3/4;
}
.javascriptIcon {
  grid-column: 2/3;
  grid-row: 5/6;
}
.vueIcon {
  grid-column: 4/5;
  grid-row: 5/6;
}
.subPanelWrapper {
  grid-column: 3/4;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 47% 6% 47%;
}
.secondSkill {
  grid-column: 1/2;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 5% 30% 30% 30% 5%;
  grid-template-rows: 25% 75%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.secondSkill:hover {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
}
.nodeIcon {
  grid-column: 2/3;
  grid-row: 2/3;
  width: 48%;
  justify-self: center;
  align-self: center;
}
.electronIcon {
  grid-column: 3/4;
  grid-row: 2/3;
  width: 45%;
  justify-self: center;
  align-self: center;
}
.gitIcon {
  grid-column: 4/5;
  grid-row: 2/3;
  width: 40%;
  justify-self: center;
  align-self: center;
}
.hobbies {
  grid-column: 1/2;
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  grid-template-rows: 25% 75%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.hobbies:hover {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
}
.cameraIcon {
  grid-column: 2/3;
  grid-row: 2/3;
  width: 20%;
  justify-self: center;
  align-self: center;
}
.hidden {
  opacity: 0;
}

@media only screen and (max-height: 992px)  and (orientation: landscape) {
  .mainTitle {
    font-size: 2.8rem;
  }
  .panelTitle {
    font-size: 1.5rem;
  }
}
@media only screen and (max-height: 450px)  and (orientation: landscape) {
  .mainTitle {
    font-size: 1.4rem;
  }
  .panelTitle {
    font-size: 0.7rem;
    align-self: end;
  }
  .divider {
    display: none;
  }
  .icon {
    /* width: 100%; */
    height: 55%;
    justify-self: center;
    align-self: center;
  }
  .secondSkill {
    /* grid-template-columns: 10% 40% 40% 10%; */
    grid-template-rows: 25% 75%;
  }
  .hobbies {
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: 25% 75%;
  }
}
/* @media only screen and (max-width: 1100px)  and (orientation: portrait) {
  .icon {
    height: 25%;
  }
} */
@media only screen and (max-device-width: 46.9em)  and (orientation: portrait) {
  .mainTitle {
    font-size: 1.4em;
  }
  .panelTitle {
    font-size: 0.8em;
    align-self: end;
  }
  .divider {
    display: none;
  }
  .icon {
    /* width: 100%; */
    height: 55% !important;
    justify-self: center;
    align-self: center;
  }
  .panelWrapper {
    display: grid;
    grid-template-columns: 100% !important;
    grid-template-rows: 45% 2.5% 45% !important;
  }
  .coreSkill {
    grid-column: 1/2;
    grid-row: 1/2;
    grid-template-columns: 12% 35% 6% 35% 12% !important;
    grid-template-rows: 12% 5% 37% 4% 37% 5% !important;
  }
  .subPanelWrapper {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
</style>
