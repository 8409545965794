<template>
  <div class="popup hidden" id="popup">
    <img class="closeBtn" id="closeBtn" src="@/assets/cross_icon.svg" @click="closeBtn">
    <div class="iframeWrapper" id="iframeWrapper">
      <div class="iframe" id="iframe">
        <iframe src="./demo/kidney_island/index.html" class="iframeContent"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup2',
  // data() {
  //   return {
  //     iframe: {
  //       src: '@/assets/demo/kidney_island'
  //     }
  //   }
  // },
  methods: {
    closeBtn() {
      document.getElementById('popup').classList.add('hidden');
      setTimeout(() => {
          this.$store.state.popup2 = false;
        }, 500);
    },
    calculateAspectRatio() {
      const content = document.getElementById('iframeWrapper')
      const contentScreen = document.getElementById('iframe')
      const divWidth = content.getBoundingClientRect().width;
      const divHeight = content.getBoundingClientRect().height;
      const calHeight = Math.round((divWidth/16)*9);
      const calWidth = Math.round((divHeight/9)*16);
      if (divWidth >= divHeight) {
        if (calWidth > divWidth) {
          contentScreen.style.width = divWidth + "px";
          contentScreen.style.height = calHeight + "px";
        }
        else {
          contentScreen.style.width = calWidth + "px";
          contentScreen.style.height = divHeight + "px";
        }
      }
      else {
        if (calHeight > divHeight) {
          contentScreen.style.width = calWidth + "px";
          contentScreen.style.height = divHeight + "px";
        }
        else {
          contentScreen.style.width = divWidth + "px";
          contentScreen.style.height = calHeight + "px";
        }
      }
    },
  },
  mounted() {
    this.calculateAspectRatio()
    window.addEventListener('resize', this.calculateAspectRatio);
    document.getElementById('popup').classList.remove('hidden');
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.popup {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 4rem;
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: 6% 88% 6%;
  transition: 0.4s ease-in-out;
}
.closeBtn {
  grid-column: 3/4;
  grid-row: 1/2;
  width: 25%;
  align-self: center;
  justify-self: center;
  opacity: 0.7;
  margin: 60% 40% 0 0;
  cursor: pointer;
}
.iframeWrapper {
  grid-column: 2/3;
  grid-row: 2/3;
  height: 100%;
  width: 100%;
  display: grid;
}
.iframe {
  align-self: center;
  justify-self: center;
  background-color: white;
}
.iframeContent{
  width: 100%;
  height: 100%;
}
.hidden {
  opacity: 0;
}
/* @media only screen and (max-height: 960px)  and (orientation: landscape) {
  .closeBtn {
    margin: 25% 28% 0 0;
  }
} */
@media only screen and (max-height: 450px)  and (orientation: landscape) {
  .closeBtn {
    margin: 110% 110% 0 0;
  }
}
</style>
