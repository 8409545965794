<template>
  <div class="home hidden" id="home">
    <div class="txtWrapper">
      <p class="title">Hello, my name is Simon</p>
      <p class="subTitle">Welcome to my portfolio</p>
      <p class="description">I’m a front end web developer who have experience with HTML, CSS, Javacript, Node.js and Vue.js.</p>
      <div class="buttonWrapper">
        <div class="button" @click="learnBtn">
          <p class="btnText">Learn More About Me</p>
        </div>
      </div>
    </div>

    <div class="miniMe">
      <img src="@/assets/miniMe.svg" class="miniMeImg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  methods: {
    learnBtn() {
      document.getElementById('home').classList.add('hidden');
      setTimeout(() => {
        this.$router.push('/about')
      }, 500);
    }
  },
  mounted() {
    setTimeout(() => {
      document.getElementById('home').classList.remove('hidden');
    }, 50);
    let routeName = this.$route.name;
    this.$store.state.currentRouteName = routeName;
    console.log("Current Route Name: " + routeName);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 4rem;
  display: grid;
  grid-template-columns: 10% 55% 30% 5%;
  grid-template-rows: auto;
  transition: 0.5s ease-in-out;
}
.txtWrapper {
  grid-column: 2/3;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 22% 11% 11% 12% 14% 10% 20%
}
.txtWrapper p {
  color: white;
  text-align: left;
  padding: 0;
  margin: 0;
}
.title {
  grid-column: 1/2;
  grid-row: 2/3;
  font-size: 4.2rem;
  font-weight: bold;
  align-self: end;
}
.subTitle {
  grid-column: 1/2;
  grid-row: 3/4;
  font-size: 3.5rem;
  font-weight: 350;
}
.description {
  grid-column: 1/2;
  grid-row: 4/5;
  font-size: 1.8rem;
  width: 90%;
}
.buttonWrapper {
  grid-column: 1/2;
  grid-row: 6/7;
}
.button {
  width: 40%;
  height: 100%;
  background-color: #30d69a;
  border-radius: 2.2rem;
  display: grid;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}
.button:hover {
  background-color: black;
}
.btnText {
  align-self: center;
  justify-self: center;
  font-size: 1.6rem;
  font-weight: bold;
}
.miniMe {
  grid-column: 3/4;
  grid-row: 1/2;
  display: grid;
}
.miniMeImg {
  align-self: end;
  justify-self: start;
  width: 75%;
  margin-left: 2%;
}
.hidden {
  opacity: 0;
}
@media only screen and (max-height: 960px)  and (orientation: landscape) {
  .title {
    font-size: 3.2rem;
  }
  .subTitle {
    font-size: 2.5rem;
    font-weight: 350;
  }
  .description {
    font-size: 1.3rem;
    width: 90%;
  }
  .btnText {
    font-size: 1rem;
  }
}
@media only screen and (max-height: 450px)  and (orientation: landscape) {
  .title {
    font-size: 1.3rem;
  }
  .subTitle {
    font-size: 1rem;
    font-weight: 350;
  }
  .description {
    font-size: 0.7rem;
    width: 90%;
  }
  .btnText {
    font-size: 0.6rem;
  }
}
@media only screen and (max-width: 1024px)  and (orientation: portrait) {
  .subTitle {
    font-size: 3rem;
    font-weight: 350;
  }
  .description {
    font-size: 1.4rem;
    /* width: 90%; */
  }
  .button {
    width: 75%;
    height: 100%;
  }
  .btnText {
    font-size: 1.45em;
  }
}
@media only screen and (max-width: 600px)  and (orientation: portrait) {
  .title {
    font-size: 3.3rem;
  }
  .subTitle {
    font-size: 2rem;
    font-weight: 350;
  }
  .description {
    font-size: 1rem;
    width: 90%;
  }
  .button {
    width: 75%;
    height: 100%;
  }
  .btnText {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 450px)  and (orientation: portrait) {
  .title {
    font-size: 2.3rem;
  }
  .subTitle {
    font-size: 1.5rem;
    font-weight: 350;
  }
  .description {
    font-size: 0.8rem;
    width: 90%;
  }
  .button {
    width: 75%;
    height: 100%;
  }
  .btnText {
    font-size: 0.6rem;
  }
}
@media only screen and (max-width: 360px)  and (orientation: portrait) {
  .title {
    font-size: 2rem;
  }
}
</style>
