import Vue from "vue";
import Vuex from "vuex";

// create connection ------------ //
Vue.use(Vuex);

// collection of all modules --------------//
// allowing access from vue
export const store = new Vuex.Store({
  state: {
    popup: false,
    popup2: false,
    currentRouteName: null
  },
  getters: {
    getPopup: state => state.popup,
    getPopup2: state => state.popup2,
    getCurrentRouteName: state => state.currentRouteName,
  },
  mutations: {
    leave(state) {
    state.popup = false;
    state.popup2 = false;
  },
  },
  action: {

  }
})
