<template>
  <div class="work">
    <Popup class="popup"
    v-if="popup"
     />
     <Popup2 class="popup"
     v-if="popup2"
      />
    <p class="mainTitle">My Works</p>
    <div class="workPanel1 panels hidden" id="workPanel1">
      <img class="workImg" src="@/assets/work1.png">
      <p class="workText">Built with HTML, CSS, Javascript and Electron. An interative web and desktop app that triggered by NFC token held by the visitors of an event. It also monitors the user data and their behavour while using the app.</p>
      <div class="buttonWrapper">
        <div class="button" @click="demoBtn">
          <p class="btnText">Demo</p>
        </div>
      </div>
    </div>
    <div class="workPanel2 panels hidden" id="workPanel2">
      <img class="workImg" src="@/assets/work2.png">
      <p class="workText">Built with HTML, CSS and Javascript. An infographic web app that educates doctors for the upcoming kidney medicine and its research data.</p>
      <div class="buttonWrapper">
        <div class="button" @click="demoBtn2">
          <p class="btnText">Demo</p>
        </div>
      </div>
    </div>
    <!-- <div class="workPanel3 panels hidden" id="workPanel3">
      <img class="workImg" src="@/assets/work3.png">
      <p class="workText">Built with HTML, CSS, Javascript and Vue. A web conference component for a large scale web app that is based on WebRTC.</p>
      <div class="buttonWrapper">
        <div class="button">
          <p class="btnText">Demo</p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Popup from '@/components/Popup.vue'
import Popup2 from '@/components/Popup2.vue'
export default {
  name: 'Work',
  components: {
    Popup,
    Popup2
  },
  // data() {
  //   return {
  //     popup: false
  //   }
  // },
  methods: {
    demoBtn() {
      this.$store.state.popup = true;
    },
    demoBtn2() {
      this.$store.state.popup2 = true;
    }
  },
  computed: {
    popup() {
      return this.$store.getters.getPopup;
    },
    popup2() {
      return this.$store.getters.getPopup2;
    },
  },
  mounted() {
    setTimeout(() => {
      document.getElementById('workPanel1').classList.remove('hidden');
    }, 50);
    setTimeout(() => {
      document.getElementById('workPanel2').classList.remove('hidden');
    }, 200);
    // setTimeout(() => {
    //   document.getElementById('workPanel3').classList.remove('hidden');
    // }, 400);
    let routeName = this.$route.name;
    this.$store.state.currentRouteName = routeName;
    console.log("Current Route Name: " + routeName);
  },
  beforeDestroy() {
    this.$store.commit('leave');
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.work {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 12% 88%;
  /* grid-template-columns: 30% 5% 30% 5% 30%; */
  grid-template-columns: 47% 6% 47%;
}
.mainTitle {
  grid-column: 1/5;
  grid-row: 1/2;
  text-align: left;
  color: white;
  font-size: 3.5rem;
  font-weight: bold;
  margin: 0 0 0 5%;
}
.panels {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 4rem;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 5% 40% 40% 15%;
  transition: 0.5s ease-in-out;
}
.buttonWrapper {
  grid-column: 1/2;
  grid-row: 4/5;
  width: 100%;
  display: grid;

}
.button {
  width: 50%;
  height: 60%;
  background-color: #30d69a;
  border-radius: 1.5rem;
  display: grid;
  cursor: pointer;
  justify-self: center;
  transition: 0.5s ease-in-out;
  z-index: 10;
}
.button:hover {
  background-color: black;
}
.btnText {
  align-self: center;
  justify-self: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: white;
  padding: 0;
  margin: 0;
}
.workPanel1 {
  grid-column: 1/2;
  grid-row: 2/3;
  /* display: grid; */
  /* grid-template-columns: 100%; */
  /* grid-template-rows: 5% 40% 40% 15%; */
}
.panels:hover {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
}
.workImg {
  grid-column: 1/2;
  grid-row: 2/3;
  height: 100%;
  width: auto;
  /* max-height: 100%;
  max-width: 100%; */
  justify-self: center;
  align-self: center;
  position: static;
}
.workText {
  grid-column: 1/2;
  grid-row: 3/4;
  color: white;
  margin: 10% 15% 2% 15%;
  text-align: left;
  font-size: 1.3rem;
  justify-self: center;
  align-self: start;
}
.workPanel2 {
  grid-column: 3/4;
  grid-row: 2/3;
  /* display: grid; */
  /* grid-template-columns: 100%; */
  /* grid-template-rows: 5% 40% 40% 15%; */
}
.workPanel3 {
  grid-column: 5/6;
  grid-row: 2/3;
  /* display: grid; */
  /* grid-template-columns: 100%; */
  /* grid-template-rows: 5% 40% 40% 15%; */
}
.popup {
  grid-column: 1/6;
  grid-row: 1/3;
  z-index: 15;
}
.hidden {
  opacity: 0;
}
@media only screen and (max-height: 960px)  and (orientation: landscape) {
  .mainTitle {
    font-size: 2.8rem;
  }
  .panels {
    border-radius: 3rem;
  }
  .workText {
    font-size: 0.9rem;
  }
  .btnText {
    font-size: 1rem;
  }
  .workImg {
    height: 80%;
  }
}
@media only screen and (max-height: 450px)  and (orientation: landscape) {
  .mainTitle {
    font-size: 1.4rem;
  }
  .panels {
    border-radius: 3rem;
  }
  .workText {
    font-size: 0.5rem;
  }
  .btnText {
    font-size: 0.5rem;
  }
  .workImg {
    height: 7em;
  }
}
@media only screen and (max-width: 1024px)  and (orientation: portrait) {
  .work {
    grid-template-rows: 12% 88%;
    grid-template-columns: 30% 5% 30% 5% 30%;
  }
  .panels {
    height: 75%;
    align-self: center;
    grid-template-rows: 5% 35% 45% 15%;
  }
  /* .mainTitle {
    font-size: 1.4rem;
  } */
  .workText {
    font-size: 1rem;
  }
  .btnText {
    font-size: 1rem;
  }
  .workImg {
    height: 10em;
  }
}
/* @media only screen and (max-width: 600px)  and (orientation: portrait) {
  .work {
    grid-template-rows: 12% 88%;
    grid-template-columns: 30% 5% 30% 5% 30%;
  }
  .panels {
    height: 75%;
    align-self: center;
    grid-template-rows: 5% 35% 45% 15%;
  }
  .mainTitle {
    font-size: 1.4rem;
  }
  .workText {
    font-size: 0.5rem;
  }
  .btnText {
    font-size: 0.5rem;
  }
  .workImg {
    width: 80%;
  }
} */
@media only screen and (max-device-width: 47em)  and (orientation: portrait) {
  .work {
    grid-template-rows: 12% 40% 8% 40% !important;
    grid-template-columns: 100% !important;
    height: 100%;
    width: 100%;
  }
  .panels {
    height: 100%;
    width: 100%;
    align-self: center;
    grid-template-rows: 5% 40% 40% 15%;
    grid-template-columns: 100%;
    border-radius: 3rem;
  }
  .mainTitle {
    font-size: 1.4rem;
  }
  .workPanel1 {
    grid-column: 1/2;
    grid-row: 2/3;
    /* height: 100%; */
  }
  .workPanel2 {
    grid-column: 1/2;
    grid-row: 4/5;
    /* height: 100%; */
  }
  .workPanel3 {
    grid-column: 1/2;
    grid-row: 6/7;
    /* height: 100%; */
  }
  .workText {
    font-size: 0.6rem;
    align-self: center;
    margin: 3% 15% 2% 15%;

  }
  .btnText {
    font-size: 0.5rem;
  }
  .workImg {
    height: 7em;
    position: static;
    /* display: none; */
  }
}
@media only screen and (max-width: 360px)  and (orientation: portrait) {
  .workImg {
    height: 4em;
    position: static;
  }
}
</style>
