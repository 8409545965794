<template>
  <div class="work">
    <div class="emailPanel hidden" id="emailPanel">
      <img class="emailIcon" src="@/assets/email_icon.svg">
      <p class="emailText"><a href="mailto:simonxingliu@gmail.com">simonxingliu@gmail.com</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  // data() {
  //   return {
  //     popup: false
  //   }
  // },
  methods: {
    demoBtn() {
      this.$store.state.popup = true;
    },
    demoBtn2() {
      this.$store.state.popup2 = true;
    }
  },
  computed: {
    popup() {
      return this.$store.getters.getPopup;
    },
    popup2() {
      return this.$store.getters.getPopup2;
    },
  },
  mounted() {
    setTimeout(() => {
      document.getElementById('emailPanel').classList.remove('hidden');
    }, 50);
    // setTimeout(() => {
    //   document.getElementById('workPanel2').classList.remove('hidden');
    // }, 200);
    // setTimeout(() => {
    //   document.getElementById('workPanel3').classList.remove('hidden');
    // }, 500);
    let routeName = this.$route.name;
    this.$store.state.currentRouteName = routeName;
    console.log("Current Route Name: " + routeName);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.work {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 35% 30% 35%;
  grid-template-columns: 25% 50% 25%;
}
.mainTitle {
  grid-column: 1/5;
  grid-row: 1/2;
  text-align: left;
  color: white;
  font-size: 3.5rem;
  font-weight: bold;
  margin: 0 0 0 5%;
}
.emailPanel {
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 4rem;
  transition: 0.5s ease-in-out;
  grid-column: 2/3;
  grid-row: 2/3;
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: auto;
}
.buttonWrapper {
  grid-column: 1/2;
  grid-row: 4/5;
  width: 100%;
  display: grid;

}
.button {
  width: 50%;
  height: 60%;
  background-color: #30d69a;
  border-radius: 1.5rem;
  display: grid;
  cursor: pointer;
  justify-self: center;
  transition: 0.5s ease-in-out;
}
.button:hover {
  background-color: black;
}
.btnText {
  align-self: center;
  justify-self: center;
  font-size: 1.4rem;
  font-weight: bold;
  color: white;
}
.emailIcon {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 60%;
  height: auto;
  justify-self: center;
  align-self: center;
  position: static;
}
.emailText {
  grid-column: 2/3;
  grid-row: 1/2;
  color: white;
  text-align: center;
  font-size: 2.3rem;
  justify-self: left;
  align-self: center;
}
.emailText a {
  color: white;
}
.popup {
  grid-column: 1/6;
  grid-row: 1/3;
  z-index: 15;
}
.hidden {
  opacity: 0;
}
@media only screen and (max-height: 960px)  and (orientation: landscape) {
  .emailText {
    font-size: 1.7rem;
  }
}
@media only screen and (max-height: 450px)  and (orientation: landscape) {
  .emailText {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1024px)  and (orientation: portrait) {
  .work {
    display: grid;
    grid-template-rows: 35% 30% 35%;
    grid-template-columns: 5% 90% 5%;
  }
  .emailText {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 47em)  and (orientation: portrait) {
  .work {
    display: grid;
    grid-template-rows: 30% 40% 30%;
    /* grid-template-columns: 25% 50% 25%; */
  }
  .emailPanel{
    grid-template-columns: auto;
    grid-template-rows: 50% 50%;
  }
  .emailIcon {
    grid-column: 1/2;
    grid-row: 1/2;
    /* height: 80%; */
    width: 38%;
    justify-self: center;
    align-self: end;
    position: static;
  }
  .emailText {
    grid-column: 1/2;
    grid-row: 2/3;
    font-size: 0.8rem;
    justify-self: center;
    align-self: start;
    margin-top: 15%;
  }
}
</style>
